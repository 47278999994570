angular.module('app')

.config(['$locationProvider', function($locationProvider){
    $locationProvider.hashPrefix('');
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    })
}])

.config(["$stateProvider", "$urlRouterProvider", function($stateProvider, $urlRouterProvider){
    
    

    $urlRouterProvider.otherwise('/');
    
    // technique for constant header and footer
    var appRoute = {
      name: 'appRoute',
      url: '',
      views: {
        '': {
          templateUrl:'components/home/homeView.html', // app.home will populate here
          controller: 'HomeCtrl'
        },
        'footer': {
          templateUrl:'components/footer/footerView.html',
          controller:'FooterCtrl as footer'
        }
      }
    }

    var home = {
        name: 'appRoute.home', // loaded into ui-view of parent state
        url: '/',
        views: {
            'navigation': {
                templateUrl: 'components/home/navigation/navigationView.html',
                controller: 'NavigationCtrl',
            },
          'header@appRoute': {
              templateUrl: 'components/home/header/headerView.html',
              controller: 'HeaderCtrl',
          },
          'services': {
              templateUrl: 'components/home/services/servicesView.html',
              controller: 'ServicesCtrl',
          },
          'process': {
              templateUrl: 'components/home/process/processView.html',
              controller: 'ProcessCtrl',
          },
          'technologies': {
              templateUrl: 'components/home/technologies/technologiesView.html',
              controller: 'TechnologiesCtrl as techCtrl',
          },
          'contact': {
              templateUrl: 'components/home/contact/contactView.html',
              controller: 'ContactCtrl',
          },
        }
    }

    var test = {
        name: 'test',
        url: '/test',
        views: {
            'main' : {
                templateUrl: 'components/home/contact/contactView.html',
                controller: 'HomeCtrl'
            }
        }
        
    }
    
    $stateProvider.state(test);
    $stateProvider.state(appRoute);
    $stateProvider.state(home);
}]);