angular.module('app')

.controller('ServicesCtrl', ['$scope', function($scope){
  $scope.animationDiamondDuration = 0;
  $scope.animationImageDuration = 0;
  $scope.animationInfoDuration = 350;

  var iconSrc = "assets/images/icons/"
  $scope.services = [
    {
      img: iconSrc + "browser.png",
      title: "Web Development",
      details: "Creating great experiences that people won't forget. Building Web Applications that solve real problems. Yep, that's what we do."
    },
//Your brand deserves an exceptional web site that is just as awesome as your company. 
// Everybody has a website now so how do you stand out? By creating exceptional experiences that people will remember.
    {
      img: iconSrc + "smartphone.png",
      title: "Mobile Apps",
      details: "iOS & Android Apps are the norm now for most businesses. They give your customers almost instant access to your product. "
    },
    {
      img: iconSrc + "ui.png",
      title: "UI & UX Design",
      details: "Beautiful aesthetics and ease of use is a combination not often seen. Add an awesome product and your customers will always come back."
    },
    {
      img: iconSrc + "plug.png",
      title: "API Development",
      details: "Your systems may need to communicate with each other, and often you need to integrate 3rd party APIs into your project."
    },
    {
      img: iconSrc + "3d.png",
      title: "WEBGL 3D Rendering",
      details: "Showcase your 3D product models to your customers online to help them visualize your ideas or innovative technology."
    },
    {
      img: iconSrc + "games.png",
      title: "Mobile Games",
      details: "We develop 2D games that are cross-device compatible. Bring in your idea, and let's talk about it."
    },
  ]
}]);