angular.module('app')

.controller('ProcessCtrl', ['$scope', function($scope){

  $scope.animationNumberDuration = 0;
  $scope.animationTitleDuration = 200;
  $scope.animationDetailsDuration = 300;

  $scope.processes = [
    {
      number: 1,
      title: 'Research & Planning',
      details: 'Exceptional applications are not accidents. Laying out a roadmap, and creating a gameplan will ensure we are making headway in the right direction. This is the time to set product expectations, decide technology needs & make sure we have everything needed to make your project a reality.'
    },
    {
      number: 2,
      title: 'Wireframe & Design',
      details: "A very fun & creative part of every project. Here we start brainstorming and fleshing out designs to visualize how your project should look, feel and flow. We'll keep chiseling until we get that perfect prototype. This step - just like planning - pays dividends in the long run."
    },
    {
      number: 3,
      title: 'Development',
      details: "Here we start getting our hands dirty. We'll start programming and architecting your application. We develop using the latest technologies and best practices to ensure your project is right there on the cutting edge. We'll talk often to keep track of progress & possible readjustments."
    },
    {
      number: 4,
      title: 'Launch & Deploy',
      details: "Hurrah, your product is complete! Now we need to deploy it to its respective marketplace or hosting provider. We'll ensure you have everything you need to get your masterpiece in front of customers, and make the best decision to ensure easy maintainability and upkeep."
    },
  ]
}]);