angular.module('app')

.controller('TechnologiesCtrl', function(){
  var src = "assets/images/technologies/"

  this.slides = [
    {
      title: "Bootstrap",
      image: src + "angularjs.png"
    },
    {
      title: "Bootstrap",
      image: src + "bootstrap-stack.png"
    },
    {
      title: "Bootstrap",
      image: src + "html5.png"
    },
    {
      title: "Bootstrap",
      image: src + "photoshop.jpg"
    },
    {
      title: "Bootstrap",
      image: src + "github.png"
    },
    {
      title: "Bootstrap",
      image: src + "sketch.png"
    },
    {
      title: "Bootstrap",
      image: src + "lua.png"
    },
    {
      title: "Bootstrap",
      image: src + "mongolab.png"
    },
    {
      title: "Bootstrap",
      image: src + "javascript.png"
    },
    {
      title: "Bootstrap",
      image: src + "heroku.png"
    },
    {
      title: "Bootstrap",
      image: src + "mongodb.png"
    },
    {
      title: "Bootstrap",
      image: src + "c.png"
    },
    {
      title: "Bootstrap",
      image: src + "nodejs.png"
    },

    {
      title: "Bootstrap",
      image: src + "css.png"
    },
    {
      title: "Bootstrap",
      image: src + "semanticui.png"
    },
  ]

});